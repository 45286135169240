






























import { defineComponent, onBeforeMount } from '@vue/composition-api'
import { useGetters, useRouter } from '@u3u/vue-hooks'
import SingleWrapper from '@/components/SingleWrapper.vue'
import InUse from '@/components/InUse.vue'
import KeyPoints from '@/components/KeyPoints.vue'
import FeaturedReferences from '@/components/FeaturedReferences.vue'
import BigCTA from '@/components/BigCTA.vue'

export default defineComponent({
  name: 'reference-single',
  // eslint-disable-next-line vue/no-unused-components
  components: { SingleWrapper, InUse, KeyPoints, FeaturedReferences, BigCTA },
  setup() {
    const { content } = useGetters(['content'])

    onBeforeMount(() => {
      if (!content.value.hasDetail) {
        useRouter().router.push({
          name: 'NotFound',
        })
      }
    })

    return {
      content,
    }
  },
})
